export function formatCurrency(num): string {
  const isMinusNumber = num.toString().includes('-')
  const formatNumber = (n) => n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  let number = num.toString()

  number = formatNumber(number)

  if (isMinusNumber) {
    number = `-${number}`
  }

  return number
}

export function setPositionDuringFormatting(num, target, position): void {
  const preLength = target.value.length
  target.value = formatCurrency(target.value)
  const postLength = target.value.length

  if (preLength < postLength) {
    position += 1
  }

  if (postLength < preLength) {
    position -= 1
  }

  target.setSelectionRange(position, position)
}
