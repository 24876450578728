import { IValidationType } from 'utility/validation/validation-interfaces'
import { dictionary } from 'utility/dictionary'

// Enums
export const validationDictMessage = {
  default: dictionary('valFieldIsRequired'),
  ssn: dictionary('valSsnIsNotValid'),
  email: dictionary('valEmailIsNotValid'),
  zip: dictionary('valPostCodeNotValid'),
  telephone: dictionary('valPhoneNumberNotValid'),
  address: dictionary('valFieldIsRequired'),
  select: dictionary('valFieldIsRequired'),
  selection: dictionary('valFieldIsRequired'),
  age: dictionary('valAgeNotValid'),
  loanTerm: dictionary('valLoanTermNotValid'),
  minimumAmount: dictionary('valMinimumAmount'),
  maximumAmount: dictionary('valMaximumAmount'),
}

export const validationMessage = {
  default: 'Vinsamlegast fylltu út þennan reit',
  ssn: 'Ógild kennitala',
  email: 'Ógilt netfang',
  zip: 'Póstnúmer er ekki rétt',
  telephone: 'Símanúmer er rangt',
  address: 'Vinsamlegast fylltu út þennan reit',
  select: 'Vinsamlegast fylltu út þennan reit',
  selection: 'Vinsamlegast fylltu út þennan reit',
  age: 'Gefðu upp aldur á milli',
  loanTerm: 'Mögulegur lánstími er',
  minimumAmount: 'Lágmarksupphæð er',
  maximumAmount: 'Hámarksupphæð er',
}

export enum validationType {
  default = 'default',
  ssn = 'ssn',
  email = 'email',
  zip = 'zip',
  telephone = 'telephone',
  address = 'address',
  select = 'select',
  selection = 'selection',
  age = 'age',
  amount = 'amount',
}

// Validation Types
export const validationTypes: IValidationType[] = [
  {
    id: validationType.default,
    min: 1,
    max: 0,
    pattern: null,
    overwrites: [],
    validate: [validationType.default],
    isValid: false,
    dictMessages: [validationDictMessage.default],
    messages: [validationMessage.default],
  },
  {
    id: validationType.ssn,
    min: 10,
    max: 11,
    pattern: /^[0-9]{10}$/,
    overwrites: [' ', '-'],
    validate: [validationType.default, validationType.ssn],
    isValid: false,
    dictMessages: [validationDictMessage.default, validationDictMessage.ssn],
    messages: [validationMessage.default, validationMessage.ssn],
  },
  {
    id: validationType.email,
    min: 1,
    max: 0,
    /* eslint-disable */
    // @ts-ignore
    pattern:
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    /* eslint-disable */
    overwrites: [],
    validate: [validationType.default, validationType.email],
    isValid: false,
    dictMessages: [validationDictMessage.default, validationDictMessage.email],
    messages: [validationMessage.default, validationMessage.email],
  },
  {
    id: validationType.zip,
    min: 3,
    max: 3,
    pattern: /^[0-9]{3}$/,
    overwrites: [' ', '-'],
    validate: [validationType.default, validationType.zip],
    isValid: false,
    dictMessages: [validationDictMessage.default, validationDictMessage.zip],
    messages: [validationMessage.default, validationMessage.zip],
  },
  {
    id: validationType.telephone,
    min: 1,
    max: 7,
    pattern: /^[0-9]{7}$/,
    overwrites: [],
    validate: [validationType.default, validationType.telephone],
    isValid: false,
    dictMessages: [validationDictMessage.default, validationDictMessage.telephone],
    messages: [validationMessage.default, validationMessage.telephone],
  },
  {
    id: validationType.address,
    min: 1,
    max: 0,
    pattern: null,
    overwrites: [],
    validate: [validationType.default],
    isValid: false,
    dictMessages: [validationDictMessage.default],
    messages: [validationMessage.default],
  },
  {
    id: validationType.select,
    min: 1,
    max: 0,
    pattern: null,
    overwrites: [],
    validate: [validationType.select],
    isValid: false,
    dictMessages: [validationDictMessage.select],
    messages: [validationMessage.select],
  },
  {
    id: validationType.selection,
    min: 1,
    max: 0,
    pattern: null,
    overwrites: [],
    validate: [validationType.selection],
    isValid: false,
    dictMessages: [validationDictMessage.selection],
    messages: [validationMessage.selection],
  },
  {
    id: validationType.age,
    min: 2,
    max: 2,
    pattern: null,
    overwrites: [],
    validate: [validationType.age],
    isValid: false,
    dictMessages: [validationDictMessage.age, validationDictMessage.loanTerm],
    messages: [validationMessage.age, validationMessage.loanTerm],
  },
  {
    id: validationType.amount,
    min: 2,
    max: 2,
    pattern: null,
    overwrites: [],
    validate: [validationType.amount],
    isValid: false,
    dictMessages: [validationDictMessage.minimumAmount, validationDictMessage.maximumAmount],
    messages: [validationMessage.minimumAmount, validationMessage.maximumAmount],
  },
]

export const testRegex = (value: string, pattern: RegExp): boolean => {
  return pattern.test(value)
}
