import { IValidationType, IValidationPlugin } from 'utility/validation/validation-interfaces'

import { validationTypes, validationType, testRegex } from 'utility/validation/validation-utility'

import { dictionary } from 'utility/dictionary'
import { formatCurrency } from 'utility/formatCurrency'

const validation: IValidationPlugin = {
  init({ items }): void {
    if (items) {
      Array.prototype.forEach.call(items, (item: HTMLInputElement) => {
        item.addEventListener('keyup', (e: Event & { target: HTMLInputElement }) => {
          this.validate(e.target, item, false)
        })
      })
    }
  },

  validateForm(items: NodeListOf<HTMLInputElement>, hasDict = true) {
    let isValid = true

    Array.prototype.forEach.call(items, (item: HTMLInputElement) => {
      const validateValue = this.validate(item, item, true, hasDict)

      if (!validateValue) {
        isValid = false
      }
    })

    return isValid
  },

  validate(target: HTMLInputElement, item: HTMLInputElement, isReturn = false, hasDict = true) {
    const validation = validationTypes.find((o) => o.id === item.dataset.validate)
    const isValid = true

    if (!validation) return

    if (target.value.length > 0 || !target.checked || target.value !== '') {
      switch (validation.id) {
        case validationType.default:
          if (isReturn) {
            return this.validateDefault(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateDefault(target, validation, isValid, isReturn, hasDict)
          }
          break
        case validationType.age:
          this.replacer(target)
          if (isReturn) {
            return this.validateAge(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateAge(target, validation, isValid, isReturn, hasDict)
          }
          break
        case validationType.amount:
          this.replacer(target)
          if (isReturn) {
            return this.validateAmount(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateAmount(target, validation, isValid, isReturn, hasDict)
          }
          break
        case validationType.ssn:
          this.replacer(target)

          if (isReturn) {
            return this.validateSsn(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateSsn(target, validation, isValid, isReturn, hasDict)
          }
          break
        case validationType.email:
          this.replaceSpace(target)

          if (isReturn) {
            return this.validateEmail(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateEmail(target, validation, isValid, isReturn, hasDict)
          }
          break
        case validationType.zip:
          this.replacer(target)

          if (isReturn) {
            return this.validateZip(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateZip(target, validation, isValid, isReturn, hasDict)
          }
          break
        case validationType.telephone:
          this.replacer(target)

          if (isReturn) {
            return this.validateTelephone(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateTelephone(target, validation, isValid, isReturn, hasDict)
          }
          break
        case validationType.address:
          if (isReturn) {
            return this.validateAddress(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateAddress(target, validation, isValid, isReturn, hasDict)
          }
          break
        case validationType.select:
          if (isReturn) {
            return this.validateSelect(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateSelect(target, validation, isValid, isReturn, hasDict)
          }
          break
        case validationType.selection:
          if (isReturn) {
            return this.validateSelection(target, validation, isValid, isReturn, hasDict)
          } else {
            this.validateSelection(target, validation, isValid, isReturn, hasDict)
          }
          break
      }
    } else {
      this.set(target, validation.dictMessages[0])
    }
  },

  // Validations
  validateDefault(
    target: HTMLInputElement,
    validation: IValidationType,
    isValid: boolean,
    isReturn = false,
    hasDict = true
  ) {
    if (target.value.length >= validation.min) {
      this.reset(target)
    } else {
      isValid = false
      this.set(target, hasDict ? validation.dictMessages[0] : validation.messages[0])
    }

    if (isReturn) {
      return isValid
    }
  },
  validateSsn(
    target: HTMLInputElement,
    validation: IValidationType,
    isValid: boolean,
    isReturn = false,
    hasDict = true
  ) {
    // TODO: Add kennitala package validation and use this one as fallback

    if (!testRegex(target.value, validation.pattern)) {
      this.set(target, hasDict ? validation.dictMessages[1] : validation.messages[1])
      isValid = false
    } else {
      this.reset(target)
    }

    if (isReturn) {
      return isValid
    }
  },
  validateEmail(
    target: HTMLInputElement,
    validation: IValidationType,
    isValid: boolean,
    isReturn = false,
    hasDict = true
  ) {
    if (!testRegex(target.value, validation.pattern)) {
      this.set(target, hasDict ? validation.dictMessages[1] : validation.messages[1])
      isValid = false
    } else {
      this.reset(target)
    }

    if (isReturn) {
      return isValid
    }
  },
  validateZip(
    target: HTMLInputElement,
    validation: IValidationType,
    isValid: boolean,
    isReturn = false,
    hasDict = true
  ) {
    if (!testRegex(target.value, validation.pattern)) {
      this.set(target, hasDict ? validation.dictMessages[1] : validation.messages[1])
      isValid = false
    } else {
      this.reset(target)
    }

    if (isReturn) {
      return isValid
    }
  },
  validateTelephone(
    target: HTMLInputElement,
    validation: IValidationType,
    isValid: boolean,
    isReturn = false,
    hasDict = true
  ) {
    if (!testRegex(target.value, validation.pattern)) {
      this.set(target, hasDict ? validation.dictMessages[1] : validation.messages[1])
      isValid = false
    } else {
      this.reset(target)
    }

    if (isReturn) {
      return isValid
    }
  },
  validateAddress(
    target: HTMLInputElement,
    validation: IValidationType,
    isValid: boolean,
    isReturn = false,
    hasDict = true
  ) {
    // TODO: Figure out a better way to validate addressess
    if (target.value.length >= validation.min) {
      this.reset(target)
    } else {
      isValid = false
      this.set(target, hasDict ? validation.dictMessages[1] : validation.messages[1])
    }

    if (isReturn) {
      return isValid
    }
  },
  validateSelect(
    target: HTMLInputElement,
    validation: IValidationType,
    isValid: boolean,
    isReturn = false,
    hasDict = true
  ) {
    if (target.value !== '') {
      this.reset(target)
    } else {
      isValid = false
      this.set(target, hasDict ? validation.dictMessages[0] : validation.messages[0])
    }

    if (isReturn) {
      return isValid
    }
  },
  validateSelection(target: HTMLInputElement, validation: IValidationType, isValid: boolean, isReturn = false) {
    const parent = <HTMLElement>target.parentNode

    if (target.checked) {
      parent.classList.remove('pretty-check--error')
      // this.reset(target)
    } else {
      isValid = false
      parent.classList.add('pretty-check--error')
      // this.set(target, validation.messages[0])
    }

    if (isReturn) {
      return isValid
    }
  },
  validateAge(
    target: HTMLInputElement,
    validation: IValidationType,
    isValid: boolean,
    isReturn = false,
    hasDict = true
  ) {
    const isAge = target.dataset.type === 'loanTerm' ? false : true
    const targetValue = Number(target.value)
    const minValue = Number(target.min)
    const maxValue = Number(target.max)
    const messageAge = hasDict
      ? validation.dictMessages[0]
      : validation.messages[0] + ' ' + target.min + ' - ' + target.max
    const messageLoanTerm = hasDict
      ? validation.dictMessages[1]
      : validation.messages[1] + ' ' + target.min + ' - ' + target.max + ' ' + dictionary('formYears')

    const message = isAge ? messageAge : messageLoanTerm

    if (targetValue < minValue || targetValue > maxValue) {
      isValid = false
      this.set(target, message)
    } else {
      this.reset(target)
    }

    if (isReturn) {
      return isValid
    }
  },

  validateAmount(
    target: HTMLInputElement,
    validation: IValidationType,
    isValid: boolean,
    isReturn = false,
    hasDict = true
  ) {
    const targetValue = Number(target.value.toString().replaceAll('.', ''))
    const minValue = Number(target.min)
    const maxValue = Number(target.max)
    const message = hasDict
      ? validation.dictMessages[0]
      : validation.messages[0] + ' ' + formatCurrency(target.min) + dictionary('formISK') + ' ' + hasDict
        ? validation.dictMessages[0]
        : validation.messages[0] + ' ' + formatCurrency(target.max) + dictionary('formISK')

    if (targetValue < minValue || targetValue > maxValue) {
      isValid = false
      this.set(target, message)
    } else {
      this.reset(target)
    }

    if (isReturn) {
      return isValid
    }
  },

  // Replacers
  replacer(target: HTMLInputElement): void {
    this.replaceDash(target)
    this.replaceSpace(target)
  },
  replaceDash(target: HTMLInputElement): void {
    if (target.value.includes('-')) {
      target.value = target.value.replace('-', '')
    }
  },
  replaceSpace(target: HTMLInputElement): void {
    if (target.value.includes(' ')) {
      target.value = target.value.replace(' ', '')
    }
  },

  // Setters
  set(target: HTMLInputElement, message: string): void {
    let parent = <HTMLElement>target.parentNode

    if (parent.classList.contains('input-group__wrap')) {
      parent = <HTMLElement>target.parentNode.parentNode
    }

    const error = parent.querySelector<HTMLElement>('.input-group__error')

    parent.classList.add('input-group--error')
    error.classList.remove('hide')
    error.innerHTML = message
  },

  reset(target: HTMLInputElement): void {
    let parent = <HTMLElement>target.parentNode

    if (parent.classList.contains('input-group__wrap')) {
      parent = <HTMLElement>target.parentNode.parentNode
    }

    const error = parent.querySelector<HTMLElement>('.input-group__error')

    parent.classList.remove('input-group--error')
    error.classList.add('hide')
    error.innerHTML = ''
  },
}

export default validation
