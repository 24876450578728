document.addEventListener('DOMContentLoaded', () => {
  // // Accessibility for checkbox hack (pretty-check)
  // if (document.querySelector<HTMLElement>('.pretty-check')) {
  //   import('../../scripts/components/pretty-check').then((module) => module.default.init())
  // }

  if (document.querySelector('.auth-login')) {
    import('../scripts/components/authForm').then((module) => module.default.init())
  }

  setTimeout((): void => {
    document.querySelector('.body').classList.remove('preload')
  }, 250)
})
