import validation from 'utility/validation/validation-plugin'

const AuthForm = {
  el: {
    root: document.querySelectorAll<HTMLElement>('.auth-login'),
    ind: 0,
  },

  init() {
    if (this.el.root.length > 0) {
      this.el.root.forEach((target) => {
        const el = {
          submitButton: target.querySelector<HTMLButtonElement>('button[type="submit"]'),
          message: target.querySelector<HTMLElement>('.auth-form__message'),
          inputs: target.querySelectorAll<HTMLInputElement>('[required]'),
          authMethodBtns: target.querySelectorAll<HTMLButtonElement>('.auth__method-btn'),
          authForms: target.querySelectorAll<HTMLFormElement>('.auth-form'),
          waiting: target.querySelector('.auth__waiting'),
          waitingTexts: target.querySelectorAll('.auth__waiting-text'),
          challengeTexts: target.querySelectorAll<HTMLHeadingElement>('.auth__waiting-challenge'),
        }

        this.inputsHaveValue(el, this.el.ind)

        el.authMethodBtns.forEach((btn, i) => {
          btn.addEventListener('click', () => {
            this.el.ind = i
            this.selectMethod(i, el)
          })
        })

        el.inputs.forEach((input) => {
          input.addEventListener('keyup', () => {
            this.inputsHaveValue(el, this.el.ind)
          })

          input.addEventListener('click', () => {
            this.inputsHaveValue(el, this.el.ind)
          })
        })

        el.authForms.forEach((authForm) => {
          authForm.addEventListener('submit', (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.submitForm(e.target, el)
          })
        })
      })
    }
  },

  selectMethod(ind, el) {
    el.waiting.classList.add('hide')
    el.authForms.forEach((authForm) => {
      authForm.classList.add('hide')
    })
    el.authMethodBtns.forEach((authBtn) => {
      authBtn.classList.remove('auth__method--selected')
    })
    el.waitingTexts.forEach((text) => {
      text.classList.add('hide')
    })
    el.challengeTexts.forEach((challenge) => {
      challenge.classList.remove('challenge--active')
    })

    el.authForms[ind].classList.remove('hide')
    el.authMethodBtns[ind].classList.add('auth__method--selected')
    el.waitingTexts[ind].classList.remove('hide')
    el.challengeTexts[ind].classList.add('challenge--active')
  },

  inputsHaveValue(el, ind) {
    let inputsHaveValue = true
    const form = el.authForms[ind]
    const inputs = form.querySelectorAll('[required]')
    const submitButton = form.querySelector('button[type="submit"]')

    inputs.forEach((input) => {
      if (input.type === 'tel') {
        if (input.value.length === 0 || input.value === '' || input.value === null) {
          inputsHaveValue = false
        }
      }

      if (input.type === 'checkbox') {
        if (!input.checked) {
          inputsHaveValue = false
        }
      }
    })

    if (inputsHaveValue) {
      submitButton.disabled = false
    } else {
      submitButton.disabled = true
    }
  },

  submitForm(form, el) {
    el.inputs = form.querySelectorAll('[required]')
    if (validation.validateForm(el.inputs, false)) {
      el.waiting.classList.remove('hide')
      form.classList.add('hide')
    }
  },
}

export default AuthForm
